import React from 'react'
import { RecoverEmailBySubnum } from 'starterComponents'
import Seo from '../components/seo'

const RecoverEmailPage = (props) => {
    return (
        <div>
            <Seo title="Recover Email - ATSG" />
            <RecoverEmailBySubnum />
        </div>
    )
}

export default RecoverEmailPage
